<template>
  <div class="text-left ChoosePlanNew" style="overflow-x: hidden">
    <div class="row Choose-plan-Main">
      
      <div class="col-12 topSection mb-3 mt-3 mt-xl-1">
        <!-- <h1 class="title ">Spend a Little. Sell a Lot.</h1> -->
        <h1 class="title">Unlock Your Website’s Full Power</h1>
        <h1 class="subText">
          <span class="highlightText">Enjoy 7 days of free trial!</span> All
          plans come with 7 days free trial - the perfect time to create and
          test your product recommendation quiz
        </h1>
      </div>

      <div class="col-12 d-flex justify-content-center mb-5" v-if="monthlyPlans.length">
        <div class="btn-group" v-if="selectedBtn == 'yearly'">
          <div class="d-flex align-items-center">
            <button
              class="btn px-4 py-2"
              :class="selectedBtn == 'yearly' ? 'selectedBtn' : null"
            >
              Yearly
            </button>
            <p class="m-0 px-4 py-2" @click="changeSelectedBtn('monthly')">
              Monthly
            </p>
          </div>
        </div>
        <div class="btn-group" v-else>
          <div class="d-flex align-items-center">
            <p
              class="m-0 px-4 py-2 yearly"
              @click="changeSelectedBtn('yearly')"
            >
              Yearly
            </p>
            <button
              class="btn px-4 py-2"
              :class="selectedBtn == 'monthly' ? 'selectedBtn' : null"
            >
              Monthly
            </button>
          </div>
        </div>
      </div>

      <div class="col-12 mt-3" v-if="isPlanLoading">
        <PricingCardSkeletonVue/>
      </div>
      <div class="col-12 mt-3 " v-else>
        <!-- Yearly Plan start-->
        <div class="row  d-flex justify-content-center" v-if="selectedBtn == 'yearly'">
          <div
            class="col-12   col-sm-10 mx-auto mx-xl-0 col-md-12 col-lg-4 col-xl-3 mb-3 h-100"
            v-for="(plan, index) in yearlyPlans"
            :key="index"
          >
          <PricingPlanCard  :plan="plan" :user="user" :mode="'shopify'"/>
          </div>
        </div>
        <!-- Yearly Plan end-->

        <!-- Monthly Plan starts -->
        <div class="row  d-flex justify-content-center" v-else>
          <div
            class="col-12 col-sm-10 mx-auto mx-xl-0 col-md-12 col-lg-4 col-xl-3  mb-3 h-100"
            v-for="(plan, index) in monthlyPlans"
            :key="index"
            style="height: 100%"
          >
            <PricingPlanCard  :plan="plan" :user="user" :mode="'shopify'"/>
          </div>
        </div>
        <!-- Monthly Plan ends -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
const Paddle = window.Paddle;
import PricingPlanCard from "./PricingPlanCard.vue"
import PricingCardSkeletonVue from "../Dashboard/product/Skeletons/PricingCardSkeleton.vue"

export default {
  data() {
    return {
      selectedBtn: "yearly",
      planFeature: [
        {
          text: "Integrations",
        },
        {
          text: "Jump Logics",
        },
      ],
      plans: [
        {
          type: "free",
          id: 1,
          title: "Free",
          price: "0",
          priceSubText: "free forever",
          priceText:
            "All the features you need to test out your quiz. Try now for free",
          isSelected: true,
          midSectionTitle: "Free Plan Include:",
          totalEngagements: 100,
          totalQuiz: 1,
          totalQuestions: 10,
          totalProducts: 15,
          TemplateText: "FULL",
          TemplateValue: "Templates",
          AnaliticsText: "Basic",
          AnaliticsTextValue: "Analitics",
          planfeature: [
            {
              text: "Integrations",
            },
            {
              text: "Jump Logics",
            },
          ],
        },
        {
          type: "basic",
          id: 4,
          title: "Basic",
          price: "15",
          priceSubText: "Monthly",
          priceText:
            " All the features you need to increase a small shop's sales",
          isSelected: false,
          midSectionTitle: "Basic Plan Include:",
          totalEngagements: 500,
          totalQuiz: 1,
          totalQuestions: 15,
          totalProducts: 100,
          TemplateText: "FULL",
          TemplateValue: "Templates",
          AnaliticsText: "Basic",
          AnaliticsTextValue: "Analitics",
          planfeature: [
            {
              text: "Integrations",
            },
            {
              text: "Jump Logics",
            },
          ],
        },
        {
          type: "pro",
          id: 5,
          title: "Pro",
          price: "49",
          priceSubText: "Monthly",
          priceText:
            "Go pro. All the features you need to increase your shop's     sales",
          isSelected: false,
          midSectionTitle: "Pro Plan Include:",
          totalEngagements: 1000,
          totalQuiz: 4,
          totalQuestions: "UNLIMITED",
          totalProducts: "UNLIMITED",
          TemplateText: "Limited",
          TemplateValue: "Templates",
          AnaliticsText: "Basic",
          AnaliticsTextValue: "Analitics",
          planfeature: [
            {
              text: "Integrations",
            },
            {
              text: "Jump Logics",
            },
          ],
        },
        {
          type: "enterprise",
          id: 6,
          title: "Enterprise",
          price: "199",
          priceSubText: "Monthly",
          priceText:
            "All the features you need to increase a big enterprise's sales",
          isSelected: false,
          midSectionTitle: "Enterprise Plan Include:",
          totalEngagements: 2000,
          totalQuiz: 10,
          totalQuestions: "UNLIMITED",
          totalProducts: "UNLIMITED",
          TemplateText: "Limited",
          TemplateValue: "Templates",
          AnaliticsText: "Basic",
          AnaliticsTextValue: "Analitics",
          planfeature: [
            {
              text: "Integrations",
            },
            {
              text: "Jump Logics",
            },
          ],
        },
      ],

      monthlyPlans: [],
      yearlyPlans: [],
      user: {},
      isPlanLoading:false,
     
    };
  },
  components:{
    PricingPlanCard,
    PricingCardSkeletonVue
  },
  methods: {
   
    // openPlan(plan) {
    //   const user_access_token = localStorage.getItem("user_access_token");
    //   const api_Url = process.env.VUE_APP_API_BASE_URL;
    //   if (user_access_token && plan.id) {
    //     const url = `${api_Url}purchase/plan?planId=${plan.id}&token=${user_access_token}`;
    //     // window.location.href = `${api_Url}billing/${plan.id}?token=${user_access_token}`;
    //     window.open(url, "_self");
    //   }
    // },
    openSupport(){
      let url = "https://support.quizell.com/"
      window.open(url,"_blank")
    },
      validatePlan(plan,planType){
        // user.planId
        // let isCurrentPlan = false;
       let planTypeMode = ''
       
        let currentPlanId = this.user.planId


        if(currentPlanId == 7 || currentPlanId ==8 || currentPlanId ==9 ){
          planTypeMode = 'yearPlan'
        } 
        if(currentPlanId == 4 || currentPlanId ==5 || currentPlanId ==6 ){
          planTypeMode = 'monthlyPlan'
        } if(currentPlanId == 1){
          planTypeMode = 'freePlan'
        }
        let isValid 
        // if(currentPlanId == plan.id){
        //   isCurrentPlan = true;
        // } 
        if(planTypeMode == 'freePlan'){
          isValid = true
        } else{
          if(planType == 'yearly'){
            if(planTypeMode == 'monthlyPlan'){
              isValid = true
            } else {
              if(currentPlanId <= plan.id){
                isValid = true
              } else {
                isValid = false
              }
            }
          } else{
               if(planTypeMode == 'yearPlan'){
              isValid = true
            } else {
              if(currentPlanId <= plan.id){
                isValid = true
              } else {
                isValid = false
              }
            }
          }
        }

          

          // console.log("Mode",planTypeMode)
          // console.log("Value",plan.id,isValid,planType)
          return isValid

      },
      async getUser() {
      const response = await axios.get(`/getCurrentUser`);

      if (response.status == 200 && response.data.status == "fail") {
        this.user = {};
      } else {
        this.user = response.data.data;
      }
    },
    changeSelectedBtn(item) {
      this.selectedBtn = item;
    },

    getTextColor(plan) {
      let style = ` color:${plan.color};`;
      return style;
    },
    async getPlans() {
      try {
        this.isPlanLoading = true
        const response = await axios.get(`/loadPricePlans`);
        // const response = await axios.get(`/loadLandingPageData`, {
        //   params: data,
        // });
        if (response.status == 200 && response.data.status == "success") {
          if(response.data.data.plans.monthly && response.data.data.plans.monthly.length){
            this.monthlyPlans = response.data.data.plans.monthly;
          }
          
          // let freePlan = response.data.data.plans.monthly[0];
          // this.yearlyPlans = [freePlan, ...response.data.data.plans.yearly];
          this.yearlyPlans = response.data.data.plans?.yearly;
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      } finally{
        this.isPlanLoading = false
      }
    },
    openPlan(plan) {
      if(plan.paddle_plan_id && plan.id !== this.user.planId
){  
        let self = this
         
            Paddle.Checkout.open({
        vendor:  parseInt( process.env.VUE_APP_PADDLE_VENDOR_ID), 
        product: plan.paddle_plan_id, 
        email: this.user.email, 
        customData:{email:this.user.email},
        quantity: 1,
        allowQuantity: false,
        successCallback: function(data) {
          data
          self.$refs.PaymentSuccessRefComp.OpenModal("success")
        },
        closeCallback: function() {
      self.$refs.PaymentSuccessRefComp.OpenModal("close")
         
        },
        passthrough: {
        
        }
      }); 
      } else {
         this.$toasted.show("Payment is not applicable for selected plan", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
      }

// console.log("Plan",plan)
      // if (plan) window.location.href = `/choosePlan/${plan.id}`;
        //  const user_access_token = localStorage.getItem("user_access_token")
        //   const api_Url = process.env.VUE_APP_API_BASE_URL
        // if(user_access_token && plan.id){
        //   const url = `${api_Url}purchase/plan?planId=${plan.id}&token=${user_access_token}`
        //   window.open(url , '_self')
        // }
   
    },
    getBtnStyles(plan) {
      let style = `mix-blend-mode: normal;
border-radius: 24px;background:${plan.bg_color};color:#ffffff;opacity:0.8;`;

      return style;
    },
    getDisabledBtnStyles(){
       let style = `mix-blend-mode: normal;
border-radius: 24px;background:#7C8290;color:#ffffff;opacity:0.8;pointer-events:none !important;`;

      return style;
    },
    isBtnDisabled(plan) {
      if (Object.entries(this.user).length !== 0) {
        if (plan.id == this.user.plan_id) return true;
        return false;
      } else {
        return false;
      }
    },
  },
  created() {
    this.getUser();
    this.getPlans();
  },
};
</script>

<style scoped>
.ChoosePlanNew {
  min-height: 100vh;
  background-image: url("/assets/img/new-website/home-main-hero2.svg");
  background-color: #ffffff;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}


.Choose-plan-Main {
  padding: 2% 5%;
}

.Choose-plan-Main .PriceTopSection .priceSection .title p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 42px;
  color: #1f1f28;
}

.Choose-plan-Main .priceSection .subText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  color: #7c8290;
  width: auto;
}


.Choose-plan-Main .btn-group {
  background: #ffffff;
  border: 1px solid #eaeaea;
  box-shadow: 0px 4px 16px rgba(77, 27, 126, 0.1);
  border-radius: 100px;
  color: #4d1b7e;
}
.Choose-plan-Main .btn-group p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  cursor: pointer;
  text-align: center;

  color: #4d1b7e;
}
.Choose-plan-Main .btn-group:hover {
  background: #4d1b7e0d;
}
.Choose-plan-Main .btn-group p:hover {
  background: #4d1b7e0d;
  /* opacity: 0.05; */
  border-radius: 0px 100px 100px 0px;
}
.Choose-plan-Main .btn-group .yearly:hover {
  background: #4d1b7e0d;
  /* opacity: 0.05; */
  border-radius: 100px 0px 0px 100px;
}

.Choose-plan-Main .btn-group .selectedBtn {
  background: #4d1b7e;
  border-radius: 100px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  color: #ffffff;

  border-radius: 100px;
}
.Choose-plan-Main .btn-group .selectedBtn:hover {
  color: #ffffff;
}
.Choose-plan-Main .topSection .title {
  color: #292929;
font-family: Poppins;
font-size: 64px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-align: center;
}
.Choose-plan-Main .topSection .subText {
  color: #222;
font-family: Poppins;
font-size: 30px;
font-style: normal;
font-weight: 400;
line-height: 150%;
text-align: center;
  padding: 0 5%;
}
.Choose-plan-Main .topSection .subText span{
  color: #6B3EDA;
font-weight: 700;
}


</style>
